/**
 * Created by me on 1/31/17.
 */
/**
 * Created by me on 1/15/17.
 */
import React from "react";

import Iframe from "react-iframe";

export default class BettingTools extends React.Component {
  render() {
    return (
      <div>
        <Iframe url="https://www.ipsports.net/ecps/cube-content/cube/hcap_lounge2012.html"  width="100%"/>
      </div>
    );
  }
}